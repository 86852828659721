input.form-control:hover:not(:focus):not(.is-invalid),
textarea.form-control:hover:not(:focus):not(.is-invalid) {
  border-color: #767C88 !important;
}

fieldset {
  margin-bottom: 1em;

  legend {
    border-bottom: 1px solid $primary;
    margin-bottom: 1.3rem;
  }
}

.DayPickerInput .form-control[readonly]:not(:disabled) {
  background-color: $input-bg;
}

.DayPicker {
  font-size: 0.875rem !important;
}

.nested-products {
  div[class*='col-'] {
    padding-left: 0.5rem;
    padding-right: 0;

    &:first-of-type {
      padding-left: 1rem;
    }
  }

  .remove-button {
    position: relative;
    bottom: 15px;
  }
}

.react-select-container {
  &.has-error {
    .react-select__control {
      border-color: $danger;
    }
  }

  &.has-invalid {
    .react-select__control {
      border-color: $danger;;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .react-select__control {
    
  }

  .react-select__value-container {
    padding-bottom: 1px;
    padding-top: 1px;
  }

  .react-select__indicator {
    padding-bottom: 0;
    padding-top: 0;
  }

  .react-select__menu {
    z-index: 100;
  }

  .react-select__control--is-disabled {
    background-color: #e9ecef;
    border-color: #ced4da;
    opacity: 1;
  }

  .react-select__single-value--is-disabled {
    color: #495057;
  }
}
.surplus-link-container {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;

  .lead {
    margin-bottom: 0;
  }
}

.form-text, .invalid-feedback {
  display: block;
}
