.timeline {
  list-style: none;
  padding: 0px 0 0px;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0px;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #eeeeee;
  left: 25px;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 5px;
  position: relative;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc( 100% - 55px );
  float: right;
  padding: 5px;
  position: relative;
}
.timeline > li > .timeline-badge-left {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 1.0em;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 11px;
  margin-right: -25px;
  background-color: #999999;
  z-index: 0;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline > li > .timeline-badge-right {
  //color: #fff;
  width: 60px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 1.1em;
  text-align: right;
  position: absolute;
  top: 0px;
  right: 11px;
  margin-left: -25px;
  z-index: 1;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline > li > .timeline-badge-right a {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-badge.primary {
  background-color: #2e6da4 !important;
}
.timeline-badge.success {
  background-color: #3f903f !important;
}
.timeline-badge.warning {
  background-color: #f0ad4e !important;
}
.timeline-badge.danger {
  background-color: #d9534f !important;
}
.timeline-badge.info {
  background-color: #5bc0de !important;
}
.timeline-title {
  margin-top: 0;
  font-size: 1.0em;
  font-weight: bold;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 0;
  color: #828b95;
}
.timeline-body-headline {
  font-weight: bold;
  color: #6c757d;
}
.timeline-body-other-text {
  color: #6c757d;
}
