.table {
  th {
    border-top: none;
    border-left: 1px solid #eceeef;

    // &:first-of-type {
    //   border-left: none;
    // }
    a>i.fa-caret-down,
    a>i.fa-caret-up {
      position: relative;
      left: 20px;
      margin-left: -17px;
    }
  }

  tr {
    td {
      border-top: 1px solid #eceeef;
      border-left: 1px solid #eceeef;

      // &:first-of-type {
      //   border-left: none;
      // }
    }

    &:first-of-type td {
      border-top: none;
    }

    &:last-of-type td {
      border-bottom: 1px solid #eceeef;
    }
  }

  .table-subheader {
    th:first-of-type {
      border-left: 1px solid $table-border-color;
    }
  }
}

@include table-variant(primary, $brand-lighter-alt);

.table-primary {
  color: #fff;

  th {
    border-color: rgba(0, 0, 0, .05);
    font-weight: normal;

    &,
    a {
      color: #fff;
    }
  }
}

.bg-primary {
  color: #fff;
}

.table-wrapper {
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid #E6E9EF;
  box-shadow: 0px 2px 2px 0px rgba(71, 77, 90, 0.04);
}

.custom-table {
  width: 100%;
  border-collapse: collapse; // Ensure borders don’t double up
  background: $white;

  th,
  td {
    padding: 0.5rem 1rem;
    text-align: left;
    border-bottom: 1px solid rgba(7, 56, 104, 0.05);
  }

  thead {
    background-color: var(--greys-light, #E6E9EF);
  }

  tr.info {
    background-color: var(--bg-light-blue-10, rgba(43, 133, 220, 0.10));
  }
}

.custom-table tr:hover {
  background-color: var(--greys-light, #E6E9EF);
}

.custom-table.no-hover tr:hover {
  background-color: transparent;
  cursor: default;
}
