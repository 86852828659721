.navbar {
  background: #ffffff !important;
  border-bottom: 1px solid #cccccc;
  padding: 0 1rem;
}

.navbar-toggler {
  margin: 10px 0;
}

.navbar-brand {
  padding: 6px 0;

  & > img {
    padding: 6px 0;
  }
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 0;
}

.dropdown-toggle.nav-link > svg {
  margin-right: 5px;
}

@include media-breakpoint-up(md) {
  .dropdown.nav-item {
    // &.active,
    // &.active:hover,
    // &.active + .nav-item {
    //   border-left: 1px solid #818a91;
    // }
    &.active {
      border-bottom: 3px solid $primary;
    }

    &:hover {
      border-bottom: 3px solid tint-color($primary, 10%);
    }
  }
  .nav-item.nav-link {
    line-height: 42px;
    padding: 6px 14px;
    border-left: 1px solid #eceeef;

    &.active,
    &.active:hover,
    &:hover {
      padding-bottom: 3px;
      border-bottom: 3px solid $primary;
    }
    // &.active,
    // &.active:hover,
    // &.active + .nav-item {
    //   border-left: 1px solid #818a91;
    // }
    &:hover {
      border-bottom: 3px solid tint-color($primary, 10%);
    }
  }

  .nav-item .nav-link {
    line-height: 42px;
    padding: 6px 14px;
    border-left: 1px solid #eceeef;
  }
}

.navbar .environment-bar .container-fluid {
  padding: 0;
}
