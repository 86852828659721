.shipping-note-container {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}

.show-more-btn {
  background-image: url('../../images/fade.png');
  background-repeat: repeat-x;
  background-position: center top;
  bottom: 0;
  font-weight: bold;
  padding-top: 30px;
  position: absolute;
  width: 100%;
  text-align: center;
}

.address_admin_sets label {
  display: block;
  margin-top: 5px;
  clear: left;
}

.address_admin_sets .btn-group {
  flex-wrap: wrap;
}
