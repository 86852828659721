.content-box {
  @include clearfix;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 7px;
  border-top: 6px solid $brand-lighter;
  padding: 2rem;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(135, 135, 135, 0.75);
  -moz-box-shadow: 0 2px 5px 0 rgba(135, 135, 135, 0.75);
  box-shadow: 0 2px 5px 0 rgba(135, 135, 135, 0.75);
}

.content-box-v2 {
  padding: 2rem;
  margin-bottom: 20px;
}

.unauthenticated-card-box {
  padding: 2rem;
  width: 30rem;
  gap: 2rem;
}

.unauthenticated-main-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-content {
  padding-top: 4.625rem;
  padding-bottom: 4.625rem;
}
