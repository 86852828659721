.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: $light-blue;
  border-color: $light-blue;
}

.btn-success,
.btn-danger,
.btn-info {
  color: $white !important;
}