span.badge.inventory {
  font-size: 12px;
  background-color: #6c767e;
  color: #fff;
  padding: 6px;
}

span.badge.inventory.badge-draft {
  background-color: #6c767e;  
}
span.badge.inventory.badge-requested {
  background-color: #edc659;  
}
span.badge.inventory.badge-request_confirmed {
  background-color: #f98e44;  
}
span.badge.inventory.badge-confimed_by_bwxt {
  background-color: #03345e;  
}
span.badge.inventory.badge-in_transit {
  background-color: #5bc1df;  
}
span.badge.inventory.badge-at_mnx {
  background-color: #5cb95c;  
}
h5.underlined.blue_line, .finance-status-header.underlined.blue_line {
  border-bottom: 1px solid #19549d;
}
.space_left{
  margin-left: 10px;
}
table.doses_table.table.table-bordered.table-striped > thead {
  background-color: #19549d;
  color: #fff;
}
table.doses_table.table.table-bordered.table-striped > thead > tr > th {
  padding: 20px;
}

table.doses_table.table.table-bordered.table-striped > tbody > tr > td {
  padding-left: 20px;
}

table.doses_table.table.table-bordered.table-striped > thead > tr > th.zero_left_space {
  padding-right: 13px;
}

.inventory-transfer-orders-table-container {
  margin-top: 30px;
}

.ito_cancel_request_button {
  margin-left: 10px;
}
