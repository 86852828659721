// Variables
@import "~bootstrap/scss/functions";

// Color system
$brand-primary:             #00477F;
$brand-lighter:             #2c5697;
$brand-lighter-alt:         #305994;
$brand-pink:                #a277a6;
$brand-success:             #58BFA7;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #DD4F71;

// scss-docs-start color-variables
$light-blue: #2B85DC !default;
$dark-blue: #00477F !default;
$black-blue: #283B49 !default;
$grey-blue: #CAD2D8 !default;
$light-grey-blue: #FAFBFD !default;
$grey-light: #E6E9EF !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$custom-colors: (
  "light-blue": $light-blue,
  "dark-blue":  $dark-blue,
  "black-blue": $black-blue,
  "grey-blue":  $grey-blue,
  "light-grey-blue":  $light-grey-blue,
  "grey-light": $grey-light,
) !default;
// scss-docs-end colors-map

$light-blue-50: shade-color($light-blue, 5%) !default;
$light-blue-100: shade-color($light-blue, 10%) !default;
$light-blue-200: shade-color($light-blue, 20%) !default;
$light-blue-300: shade-color($light-blue, 30%) !default;
$light-blue-400: shade-color($light-blue, 40%) !default;
$light-blue-500: shade-color($light-blue, 50%) !default;
$light-blue-600: shade-color($light-blue, 60%) !default;
$light-blue-700: shade-color($light-blue, 70%) !default;
$light-blue-800: shade-color($light-blue, 80%) !default;
$light-blue-900: shade-color($light-blue, 90%) !default;

$dark-blue-50: shade-color($dark-blue, 5%) !default;
$dark-blue-100: shade-color($dark-blue, 10%) !default;
$dark-blue-200: shade-color($dark-blue, 20%) !default;
$dark-blue-300: shade-color($dark-blue, 30%) !default;
$dark-blue-400: shade-color($dark-blue, 40%) !default;
$dark-blue-500: shade-color($dark-blue, 50%) !default;
$dark-blue-600: shade-color($dark-blue, 60%) !default;
$dark-blue-700: shade-color($dark-blue, 70%) !default;
$dark-blue-800: shade-color($dark-blue, 80%) !default;
$dark-blue-900: shade-color($dark-blue, 90%) !default;

$black-blue-50: shade-color($black-blue, 5%) !default;
$black-blue-100: shade-color($black-blue, 10%) !default;
$black-blue-200: shade-color($black-blue, 20%) !default;
$black-blue-300: shade-color($black-blue, 30%) !default;
$black-blue-400: shade-color($black-blue, 40%) !default;
$black-blue-500: shade-color($black-blue, 50%) !default;
$black-blue-600: shade-color($black-blue, 60%) !default;
$black-blue-700: shade-color($black-blue, 70%) !default;
$black-blue-800: shade-color($black-blue, 80%) !default;
$black-blue-900: shade-color($black-blue, 90%) !default;

$grey-blue-50: shade-color($grey-blue, 5%) !default;
$grey-blue-100: shade-color($grey-blue, 10%) !default;
$grey-blue-200: shade-color($grey-blue, 20%) !default;
$grey-blue-300: shade-color($grey-blue, 30%) !default;
$grey-blue-400: shade-color($grey-blue, 40%) !default;
$grey-blue-500: shade-color($grey-blue, 50%) !default;
$grey-blue-600: shade-color($grey-blue, 60%) !default;
$grey-blue-700: shade-color($grey-blue, 70%) !default;
$grey-blue-800: shade-color($grey-blue, 80%) !default;
$grey-blue-900: shade-color($grey-blue, 90%) !default;

$light-blues: (
  "light-blue-50": $light-blue-50,
  "light-blue-100": $light-blue-100,
  "light-blue-200": $light-blue-200,
  "light-blue-300": $light-blue-300,
  "light-blue-400": $light-blue-400,
  "light-blue-500": $light-blue-500,
  "light-blue-600": $light-blue-600,
  "light-blue-700": $light-blue-700,
  "light-blue-800": $light-blue-800,
  "light-blue-900": $light-blue-900,
) !default;

$dark-blues: (
  "dark-blue-50": $dark-blue-50,
  "dark-blue-100": $dark-blue-100,
  "dark-blue-200": $dark-blue-200,
  "dark-blue-300": $dark-blue-300,
  "dark-blue-400": $dark-blue-400,
  "dark-blue-500": $dark-blue-500,
  "dark-blue-600": $dark-blue-600,
  "dark-blue-700": $dark-blue-700,
  "dark-blue-800": $dark-blue-800,
  "dark-blue-900": $dark-blue-900,
) !default;

$black-blues: (
  "black-blue-50": $black-blue-50,
  "black-blue-100": $black-blue-100,
  "black-blue-200": $black-blue-200,
  "black-blue-300": $black-blue-300,
  "black-blue-400": $black-blue-400,
  "black-blue-500": $black-blue-500,
  "black-blue-600": $black-blue-600,
  "black-blue-700": $black-blue-700,
  "black-blue-800": $black-blue-800,
  "black-blue-900": $black-blue-900,
) !default;

$grey-blues: (
  "grey-blue-50": $grey-blue-50,
  "grey-blue-100": $grey-blue-100,
  "grey-blue-200": $grey-blue-200,
  "grey-blue-300": $grey-blue-300,
  "grey-blue-400": $grey-blue-400,
  "grey-blue-500": $grey-blue-500,
  "grey-blue-600": $grey-blue-600,
  "grey-blue-700": $grey-blue-700,
  "grey-blue-800": $grey-blue-800,
  "grey-blue-900": $grey-blue-900,
) !default;


// scss-docs-start theme-color-variables
$primary:       $dark-blue;
// $secondary:     $gray-600 !default;
$success:       $brand-success;
$info:          $light-blue;
// $warning:       $yellow !default;
$danger:        $brand-danger;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// Form validation
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'><path fill='#{$success}' d='M6.49995 17.534C6.17172 17.5347 5.8466 17.4576 5.54328 17.3071C5.23996 17.1566 4.9644 16.9357 4.73245 16.657L1.73828 13.065L2.91661 11.65L5.91078 15.243C6.06705 15.4305 6.27898 15.5358 6.49995 15.5358C6.72092 15.5358 6.93284 15.4305 7.08911 15.243L17.0833 3.25L18.2616 4.664L8.26745 16.657C8.03549 16.9357 7.75994 17.1566 7.45661 17.3071C7.15329 17.4576 6.82818 17.5347 6.49995 17.534Z'/></svg>");
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'><path d='M15.589 6.08916L14.4106 4.91083L9.99981 9.32166L5.58898 4.91083L4.41064 6.08916L8.82148 10.5L4.41064 14.9108L5.58898 16.0892L9.99981 11.6783L14.4106 16.0892L15.589 14.9108L11.1781 10.5L15.589 6.08916Z' fill='#{$danger}'/></svg>");

// Buttons + Forms
$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         1rem;
$box-shadow-inset:            0px 2px 2px 0px rgba(71, 77, 90, 0.02);
$btn-box-shadow:              $box-shadow-inset;
$input-btn-focus-box-shadow:  0px 0px 0px 2px rgba(43, 133, 220, 0.10);

// $body-color:                $black-blue;

// Font
$font-family-sans-serif:    'Inter', sans-serif;
$font-size-base:            0.875rem;

// Import Bootstrap's SCSS files
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Body
//
// Settings for the `<body>` element.
$body-bg:                   $light-grey-blue;
// $body-color:                $black-blue;


//Input
$input-bg:                  $white;
$input-border-color:        #E6E9EF; // ?????????
$input-focus-border-color:  $light-blue;

// Links
$link-color:                #474D5A; // ?????????
$link-decoration:           none;
$link-hover-color:          $light-blue;

//Nav
$navbar-nav-link-padding-x: 1rem;

$theme-colors: map-merge($theme-colors, $custom-colors);

// Update spacers map
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: 2rem, // Custom 2rem spacer
);

// Import the full Bootstrap SCSS file
@import "~bootstrap/scss/bootstrap";