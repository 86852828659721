.badge {
  // border-radius: $badge-pill-border-radius;
  border-radius: 3px;
  text-transform: uppercase;

  h1 & {
    font-size: 50%;
    font-weight: 300;
    margin-left: 5px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
}

.badge-dark {
  background-color: #000 !important;
}

.badge-mid {
  background-color: #dbdbdb !important;
  color: black;
}


.badge-info {
  color: #fff;
}

// Finance Status Badge Colours
.badge-none {
  background-color: $primary !important;
}
.badge-sent-finance {
  background-color: #4DA1F0 !important;
}
.badge-sent-s4 {
  background-color: #4DA1F0 !important;
}
.badge-order-created-in-s4 {
  background-color: #488958 !important;
}
.badge-order-creation-failed-in-s4 {
  background-color: #FF6256 !important;
}
.badge-credit-check-initiated-in-s4 {
  background-color: #4DA1F0 !important;
}
.badge-credit-check-successful-in-s4 {
  background-color: #488958 !important;
}
.badge-credit-check-failed-in-s4 {
  background-color: #FF6256 !important;
}
.badge-credit-check-incomplete-in-s4 {
  background-color: #FF6256 !important;
}
.badge-order-update-sent-to-s4 {
  background-color: #4DA1F0 !important;
}
.badge-order-update-successful-in-s4 {
  background-color: #488958 !important;
}
.badge-order-update-failed-in-s4 {
  background-color: #FF6256 !important;
}
.badge-credit-requested-in-s4 {
  background-color: #F5A623 !important;
}
.badge-credited-in-s4 {
  background-color: #674DF0 !important;
}
.badge-debit-requested-in-s4 {
  background-color: #F5A623 !important;
}
.badge-debited-in-s4 {
  background-color: #488958 !important;
}
.badge-accepted-finance {
  background-color: #488958 !important;
}
.badge-rejected-finance {
  background-color: #FF6256 !important;
}
.badge-invoiced {
  background-color: #39C258 !important;
}
.badge-credit-requested {
  background-color: #F5A623 !important;
}
.badge-credit-approved {
  background-color: #1F559C !important;
}
.badge-credited {
  background-color: #674DF0 !important;
}
.badge-finance-status-error {
  background-color: #ff5676 !important;
}
.badge-credit-rejected-finance {
  background-color: #e46175 !important;
}
.badge-accepted-finance-with-warnings {
  background-color: #49b4aa !important;
}

.badge-pending-po {
  background-color: #f542ec !important;
  color: white;
}
