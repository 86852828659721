//This custom_variables import should be alwasy on top of other imports
@import "./custom_variables";

// NB: import all not working currently
// @import './components/**/*';
@import './components/_address.scss';
@import './components/_customer.scss';
@import './components/_audit.scss';
@import './components/_badges.scss';
@import './components/_comments.scss';
// @import './components/_confirm-icons.scss';
@import './components/_forms.scss';
@import './components/_navbar.scss';
@import './components/_content_box.scss';
@import './components/_footer.scss';
@import './components/_tables.scss';
@import './components/_timeline.scss';
@import './components/_inventory.scss';
@import './components/cards';
@import './components/noty';
@import './components/buttons';

//This mainly used for footer
html {
  position: relative;
  min-height: 100%;
}

.border-bottom-primary {
  border-bottom: 3px solid $primary;
}

.flex {
  display: flex;
}

.flex-v-center {
  align-items: center;
}

.flex-h-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.height-full {
  height: 100%;
}

h1 {
  border-bottom: 2px solid #cccccc;
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
}

.underlined {
  // border-bottom: 1px solid $primary;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 1.3rem;
  line-height: 1.5;
  font-weight: normal;
}

.logo-block {
  margin-bottom: 20px;
}

.pagination-wrapper {
  border-top: 2px solid #818a91;
  text-align: center;

  .pagination {
    margin-top: 2rem;
    margin-bottom: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.container {
  max-width: 1540px;
  // width: 1140px;
  min-width: 960px;
}

.confirm-modal {
  text-align: center;

  .modal-footer {
    text-align: center;
  }

  .btn.cancel {
    margin-right: 15px;
  }
}

.hidden {
  display: none;
}

.action-buttons {
  float: right;
}

.action-buttons-left {
  float: left;
  button {
    margin-right: 5px;
  }
}

sub {
  font-size: 35%;
}

ul.list-grid {
  display: block;
  font-size: 0;
  list-style: none;
  padding: 0;

  > li {
    display: inline-block;
    float: none;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: top;
  }
}

.back-btn {
  display: inline-block;
  margin-bottom: 15px;
}

.address-contacts {
  margin-top: 15px;
}

.bold {
  font-weight: bold;
}

[data-disable-interaction] {
  background-color: #eceeef;
  cursor: not-allowed;
}

.header {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #cccccc;
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;

  h1 {
    border: 0 none;
    margin: 0;
    padding: 0;
  }

  .actions {
    align-items: center;
    display: flex;
    flex: 1;
    margin-left: 0.5em;
  }
}

.ms-auto {
  margin-left: auto;
}

.hoverable {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.with-top-arrow {
  position: relative;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }

  &:before {
    border-color: rgba(28, 33, 36, 0);
    border-bottom-color: $card-border-color;
    border-width: 6px;
    margin-left: -6px;
  }
}

.lh-1 {
  line-height: 1;
}

.ml-minus-card {
  margin-left: -1.25rem;
}

.mr-minus-card {
  margin-right: -1.25rem;
}

thead.table-borderless {
  th {
    border: 0 none;
  }
}

.text-primary-light {
  color: $brand-lighter;
}

[data-collapsible]:hover {
  cursor: pointer;
}

.DayPickerInput {
  display: block;
}

.separator {
  background-color: #ddd;
  display: inline-block;
  margin: 0 5px;
  width: 1px;

  &::before {
    content: ' ';
    display: inline-block;
  }
}

.ReactTable {
  .rt-thead {
    background-color: $brand-lighter-alt;
    color: #fff;

    .rt-th {
      padding-bottom: 0.75rem !important;
      padding-top: 0.75rem !important;
    }
  }
}

table th.dark-col,
table td.dark-col {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.btn-link-plain {
  padding: 0;
  border: 0 none;
  vertical-align: inherit;
}

.rc-input-number {
  height: auto !important;
  line-height: 1.5 !important;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
    margin: 0;
  }

  .rc-input-number-input {
    text-align: inherit;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &.is-invalid {
    border-color: #d9534f;

    .rc-input-number-input {
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .rc-input-number-handler-wrap {
    height: 33.5px;

    .rc-input-number-handler {
      height: 16.75px;
      line-height: 16.75px;
    }
  }
}

.fadeInOut-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fadeInOut-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fadeInOut-exit {
  opacity: 1;
}

.fadeInOut-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.react-timekeeper {
  .react-timekeeper__tb-hour,
  .react-timekeeper__tb-colon,
  .react-timekeeper__tb-minute {
    color: #ced4da;
  }

  .css-3u52cg-TopBar,
  .css-1uynwhj-TopBar {
    color: $brand-lighter-alt;
  }
}

.order-status-filter-form-group {
  display: inline-block;
  float: left;
}

.finance-status-filter-form-group {
  display: inline-block;
  float: right;
  margin-left: 10px;
}

.po-number-filter-form-group {
  display: inline-block;
  float: left;
  margin-top: 35px;
  margin-left: 6rem;
}

@media (max-width: 1490px) {
  .po-number-filter-form-group {
    margin-left: 15px;
  }
}

@media (max-width: 1195px) {
  .finance-status-filter-form-group {
    display: block;
    float: left;
    margin-left: 0px;
  }

  .po-number-filter-form-group {
    display: block;
    float: none;
    margin-top: 0px;
    margin-left: 0px;
  }
}

.finance-status-filter-select {
  vertical-align: middle;
  width: 300px;
}

.finance-status-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#finance-status-buttons {
  button {
    width: 100%;
    height: 45px;
    color: white;
    font-size: 1em;
    font-weight: bold;
    border-radius: 0rem;
    margin-bottom: 15px;
  }
}

.credit-request-modal-footer {
  button {
    color: white;
  }
  button:hover {
    color: white !important;
  }
}

.request-credit {
  background-color: rgb(245, 166, 35) !important;
}

.view-credit-request {
  background-color: rgb(31, 85, 156) !important;
}

.approve-credit-request {
  background-color: rgb(31, 85, 156) !important;
}

.cancel-credit-request {
  background-color: rgb(255, 98, 86) !important;
}

.update-to-invoiced {
  background-color: #2C5696 !important;
}

.mnx_error_message {
  font-weight: bold;
  margin-right: 10px;
  text-transform: capitalize;
}

.in-transit-inventory-list-table.table {
  tr.even-row {
    background-color: rgba(0,0,0,.05);
  }

  td.first-row {
    border-bottom: none;
  }

  td.last-row {
    border-top: none;
  }

  td.middle-row {
    border-top: none;
    border-bottom: none;
  }
}

table {
  caption-side: top;
}
.actual-ampoule-allocation-table-header {
  background-color: aliceblue;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  align-items: center;
}

.no-of-containers-validation-failed, .ampoule-delivery-time-format {
  border: 1px solid red;
}

.alice-blue-bg {
  background-color: aliceblue;
}

.ampoule-log-td-width {
  width: 16%;
}

.w-100 {
  width: 100%;
}

.overflow-y-inherit {
  overflow-y: inherit !important;
}

.delete-x-button {
  color: white;
  background: red;
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 7px 10px 7px;
}

.capitalize-first {
  text-transform: lowercase;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}

.b-r-4 {
  border-right: 4px solid $black !important;
}
