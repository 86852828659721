.comments-container {
  margin-bottom: 2rem;
}

.mention {
  background-color: #eceeef;
  padding: 3px;
}

.comment {
  background-color: #f4f4f4;
  margin-top: 1rem;
  padding: 1rem;

  p:last-of-type {
    margin: 0;
  }
}

.comment-meta {
  color: $text-muted;
  margin-bottom: .5rem;

  time {
    margin-left: 8px;
  }
}

.comment-user {
  font-weight: bold;
}

.comment-actions {
  margin-top: .5rem;

  a, button {
    color: $text-muted;
  }

  .spacer {
    margin: 0 .5rem;
  }
}
